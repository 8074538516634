

body {
  background-color: #FFFAFF;
}

.clip_two {
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 21% 50%, 0 0);
  
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 29% 100%); */
}

@media screen and (min-width: 280px) and (max-width: 375px) {
  
}

@media screen and (min-width: 1024px) and (max-width: 1280px) { 
  .clip_three {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 34% 52%);
  }
}

@media screen and (min-width: 640px) {
  .clip {
    clip-path: polygon(0 0, 69% 0, 100% 100%, 0% 100%);
  }

}

.ReactCollapse--collapse {
  transition: height 300ms;
}